import React from "react";

export default function Overlay({ ready, clicked, setClicked }) {
  return (
    <>
      <div
        className={`fullscreen bg ${ready ? "ready" : "notready"} ${
          clicked && "clicked"
        }`}
      >
        <div onClick={() => ready && setClicked(true)} className="pulsate">
          {!ready ? "loading" : "click to continue"}
        </div>
      </div>
    </>
  );
}
