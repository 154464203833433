import React from "react";

const Picker = ({ setColor, clicked }) => {
  const presetColors = [
    "#292826",
    "#454a4e",
    "#8f8f8d",
    "#cdcdca",
    "#e4aa16",
    "#e84a00",
    "#cc3129",
    "#5a5948",
    "#223975",
  ];
  const changeColor = (color) => {
    setColor(color);
  };

  return (
    <div style={{ display: clicked ? "block" : "none" }}>
      <div className="picker__swatches">
        {presetColors.map((presetColor) => (
          <button
            key={presetColor}
            className="picker__swatch"
            style={{ background: presetColor }}
            onClick={() => changeColor(presetColor)}
          />
        ))}
      </div>
    </div>
  );
};

export default Picker;
