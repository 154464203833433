import React, { Suspense, useState } from "react";
import * as THREE from "three";

import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";

// import Scene from "./Scene";
import Picker from "./Component/Picker";

import { EffectComposer, Bloom } from "@react-three/postprocessing";
import { Resizer, KernelSize } from "postprocessing";

import Overlay from "./Component/Overlay";


import "./App.css";

export default function App() {
  const Scene = React.lazy(() => import("./Scene"));

  const [color, setColor] = React.useState("#e4aa16");

  const [showHingedLid, setShowHingedLid] = React.useState(true);

  const [clicked, setClicked] = useState(false);
  const [ready, setReady] = useState(true);
  const store = { clicked, setClicked, ready, setReady };

  return (
    <div id="canvas-container">
      <Picker
        color={color}
        setColor={setColor}
        clicked={clicked}
      />
      <Canvas
        dpr={[1,2]}
        sRGB
        style={{ width: "100%", height: "100%" }}
        shad
        onCreated={({ gl }) => {
          gl.toneMapping = THREE.ReinhardToneMapping;
          gl.toneMapping = THREE.CineonToneMapping;
          gl.toneMapping = THREE.LinearToneMapping;
          gl.toneMapping = THREE.NoToneMapping;
          gl.toneMapping = THREE.ACESFilmicToneMapping;
        }}
        camera={{
          fov: 45,
          position: [10, 0, 80],
        }}
      >
        {/* <fog attach="fog" args={["white", 50, 230]} /> */}
        <Suspense fallback={null}>
          {clicked ? (
            <>
              <Scene
                containerBodyColor={color}
                showHingedLid={showHingedLid}
                setShowHingedLid={setShowHingedLid}
              />
              <EffectComposer>
                <Bloom
                  intensity={0.8} // The bloom intensity.
                  blurPass={undefined} // A blur pass.
                  width={Resizer.AUTO_SIZE} // render width
                  height={Resizer.AUTO_SIZE} // render height
                  kernelSize={KernelSize.LARGE} // blur kernel size
                  luminanceThreshold={0.9} // luminance threshold. Raise this value to mask out darker elements in the scene.
                  luminanceSmoothing={0.025} // smoothness of the luminance threshold. Range is [0, 1]
                />
              </EffectComposer>
              <OrbitControls
                enableZoom={true}
                minDistance={40}
                maxDistance={250}
                enableRotate={true}
                enablePan={true}
                minPolarAngle={1}
                maxPolarAngle={1.5}
                minAzimuthAngle={-Math.PI / 12}
                maxAzimuthAngle={Math.PI / 4}
                target={[5, 15, 0]}
              />
            </>
          ) : (
            <></>
          )}
        </Suspense>
      </Canvas>
      <Overlay {...store} />
    </div>
  );
}
